// $.validator.addMethod( "requiredPhone", function ( value, element, param )
// {
// 	value = value.replace( '+_(___)___-____' )

// 	if ( !this.depend( param, element ) )
// 	{
// 		return "dependency-mismatch"
// 	}

// 	if ( element.nodeName.toLowerCase() === "select" )
// 	{
// 		var val = $( element )[0].inputmask.unmaskedvalue()
// 		return val && val.length > 0
// 	}

// 	if ( this.checkable( element ) )
// 	{
// 		return this.getLength( value, element ) > 0
// 	}
// 	return $.trim( value ).length > 0
// }, "Укажите ваш телефон" )

// $.validator.addMethod( "minlengthPhone", function ( value, element, param )
// {
// 	value = value.replace( /\D/g, '' )

// 	var    length = $.isArray( value ) ? value.length : this.getLength( $.trim( value ), element )

// 	return this.optional( element ) || length >= param
// }, "Телефон должен быть не менее {0} символов" )



// Inputmask( {
// 	mask           : '+7 (999) 999-99-99',
// 	clearIncomplete: true
// } )
// 	.mask( $( '[type="tel"]' ).get() )


// $( '[type="tel"]' ).on( 'focus', function ( e )
// {
// 	Cookies.set( 'phoneCheck', '' )
// } )



// $( '[type="tel"]' ).on( 'keypress', function ( e )
// {
// 	var keycode,
// 		phoneCheck = Cookies.get( 'phoneCheck' ) ? Cookies.get( 'phoneCheck' ) : ''

// 	if   ( !e ) var e           = window.event
// 	if   ( e.keyCode ) keycode  = String.fromCharCode( e.keyCode )  // для IE
// 	else if ( e.which ) keycode = String.fromCharCode( e.which )    // для всех браузеров

// 	Cookies.set( 'phoneCheck', phoneCheck + keycode )
// } )



// Отправка формы
$( '.js-submit-form' ).on( 'click', function( e )
{
	let $form = $( this ).closest( 'form' )

	$form.validate( {
		ignore: '',
		messages: {
			privacy: {
				required: 'You should agree with processing personal data'
			},
		},
		submitHandler: function( form )
		{
			showLoader( $form )
			ajaxs( 'mailer', form, ( res ) => showResMessage( $form, res.data ) )
		}
	} )

} )
