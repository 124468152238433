if ( window.metrika !== null )
{
	$( '[data-target-ym]' ).on( 'click', function()
	{
		var target = $( this ).data( 'target' )
		ym( window.metrika, 'reachGoal', target )
	} )
}

if ( window.ga !== null )
{
	$( '[data-target-ga]' ).on( 'click', function()
	{
		var target = $( this ).data( 'target-ga' )
		ga( 'send', 'event', 'button', target )
	} )
}



$( 'a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".pdf"]:not([download])' ).fancybox()

$( '[data-fancybox]' ).fancybox()

// $().fancybox( {
// 	selector: '[data-fancybox-popup]',
// 	smallBtn: false,
// 	btnTpl: false,
// 	touch: false,
// 	toolbar: false,
// 	buttons: false,
// 	baseTpl: `
// 	<div class="fancybox-container">
// 		<div class="fancybox-bg"></div>
// 		<div class="fancybox-inner">
// 			<div class="fancybox-stage"></div>
// 		</div>
// 	</div>`,
// } )



var $searchField = $( '.js-search-field' ),
	$searchBtn = $( '.js-search-btn' )

if ( !!$searchField.length && !!$searchField.length )
{
	$searchField.on( 'input', () => search_btn_state( $searchField ) )
	$( search_btn_state( $searchField ) )

	function search_btn_state ( $elem )
	{
		$searchBtn.prop( 'disabled', $elem.val().length < 4 )
	}
}



$( document ).on( 'click', 'a[href^="#"]:not([data-fancybox],[data-fancybox-popup])', function( e )
{
	e.preventDefault()

	var target = $( this.hash )

	if ( target.length )
	{
		$( 'html, body' ).animate( {
			scrollTop: target.offset().top - 20
		}, 500 )
	}
} )
