jQuery( function ( $ )
{
	window.initScripts = function ()
	{
		if ( window.metrika !== null )
		{
			$( '[data-target-ym]' ).on( 'click', function()
			{
				var target = $( this ).data( 'target' )
				ym( window.metrika, 'reachGoal', target )
			} )
		}
		
		if ( window.ga !== null )
		{
			$( '[data-target-ga]' ).on( 'click', function()
			{
				var target = $( this ).data( 'target-ga' )
				ga( 'send', 'event', 'button', target )
			} )
		}
		
		
		
		$( 'a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".pdf"]:not([download])' ).fancybox()
		
		$( '[data-fancybox]' ).fancybox()
		
		// $().fancybox( {
		// 	selector: '[data-fancybox-popup]',
		// 	smallBtn: false,
		// 	btnTpl: false,
		// 	touch: false,
		// 	toolbar: false,
		// 	buttons: false,
		// 	baseTpl: `
		// 	<div class="fancybox-container">
		// 		<div class="fancybox-bg"></div>
		// 		<div class="fancybox-inner">
		// 			<div class="fancybox-stage"></div>
		// 		</div>
		// 	</div>`,
		// } )
		
		
		
		var $searchField = $( '.js-search-field' ),
			$searchBtn = $( '.js-search-btn' )
		
		if ( !!$searchField.length && !!$searchField.length )
		{
			$searchField.on( 'input', () => search_btn_state( $searchField ) )
			$( search_btn_state( $searchField ) )
		
			function search_btn_state ( $elem )
			{
				$searchBtn.prop( 'disabled', $elem.val().length < 4 )
			}
		}
		
		
		
		$( document ).on( 'click', 'a[href^="#"]:not([data-fancybox],[data-fancybox-popup])', function( e )
		{
			e.preventDefault()
		
			var target = $( this.hash )
		
			if ( target.length )
			{
				$( 'html, body' ).animate( {
					scrollTop: target.offset().top - 20
				}, 500 )
			}
		} )
		
		window.$loader = $(
			`<div class="loader">
				<div class="loader__inner">
					<div class="loader__dot"></div>
					<div class="loader__dot"></div>
					<div class="loader__dot"></div>
				</div>
			</div>`
		)
		
		
		
		window.showLoader = function( selector )
		{
			$( selector ).append( window.$loader.hide().fadeIn( 150 ) )
		}
		
		
		
		window.showResMessage = function( selector, text )
		{
			$( selector ).append(
				$(
					`<div class="result-overlay">
						<div class="result-overlay__inner">${ text }</div>
					</div>`
				).hide().fadeIn( 150 )
			)
		}
		
		
		
		// $( window ).on( 'ajaxs_always', function ()
		// {
		// 	$loader.remove()
		// } )
		$( window ).on( 'ajaxs_always', () => $loader.fadeOut( 150, () => $loader.remove() ) ) //
		
		// $.validator.addMethod( "requiredPhone", function ( value, element, param )
		// {
		// 	value = value.replace( '+_(___)___-____' )
		
		// 	if ( !this.depend( param, element ) )
		// 	{
		// 		return "dependency-mismatch"
		// 	}
		
		// 	if ( element.nodeName.toLowerCase() === "select" )
		// 	{
		// 		var val = $( element )[0].inputmask.unmaskedvalue()
		// 		return val && val.length > 0
		// 	}
		
		// 	if ( this.checkable( element ) )
		// 	{
		// 		return this.getLength( value, element ) > 0
		// 	}
		// 	return $.trim( value ).length > 0
		// }, "Укажите ваш телефон" )
		
		// $.validator.addMethod( "minlengthPhone", function ( value, element, param )
		// {
		// 	value = value.replace( /\D/g, '' )
		
		// 	var    length = $.isArray( value ) ? value.length : this.getLength( $.trim( value ), element )
		
		// 	return this.optional( element ) || length >= param
		// }, "Телефон должен быть не менее {0} символов" )
		
		
		
		// Inputmask( {
		// 	mask           : '+7 (999) 999-99-99',
		// 	clearIncomplete: true
		// } )
		// 	.mask( $( '[type="tel"]' ).get() )
		
		
		// $( '[type="tel"]' ).on( 'focus', function ( e )
		// {
		// 	Cookies.set( 'phoneCheck', '' )
		// } )
		
		
		
		// $( '[type="tel"]' ).on( 'keypress', function ( e )
		// {
		// 	var keycode,
		// 		phoneCheck = Cookies.get( 'phoneCheck' ) ? Cookies.get( 'phoneCheck' ) : ''
		
		// 	if   ( !e ) var e           = window.event
		// 	if   ( e.keyCode ) keycode  = String.fromCharCode( e.keyCode )  // для IE
		// 	else if ( e.which ) keycode = String.fromCharCode( e.which )    // для всех браузеров
		
		// 	Cookies.set( 'phoneCheck', phoneCheck + keycode )
		// } )
		
		
		
		// Отправка формы
		$( '.js-submit-form' ).on( 'click', function( e )
		{
			let $form = $( this ).closest( 'form' )
		
			$form.validate( {
				ignore: '',
				messages: {
					privacy: {
						required: 'You should agree with processing personal data'
					},
				},
				submitHandler: function( form )
				{
					showLoader( $form )
					ajaxs( 'mailer', form, ( res ) => showResMessage( $form, res.data ) )
				}
			} )
		
		} )
		
	}

	if ( $( '#editor' ).length )
	{
		$( document ).on( 'mb_blocks_preview', initScripts )
	}
	else
	{
		$( initScripts )
	}
} )
