window.$loader = $(
	`<div class="loader">
		<div class="loader__inner">
			<div class="loader__dot"></div>
			<div class="loader__dot"></div>
			<div class="loader__dot"></div>
		</div>
	</div>`
)



window.showLoader = function( selector )
{
	$( selector ).append( window.$loader.hide().fadeIn( 150 ) )
}



window.showResMessage = function( selector, text )
{
	$( selector ).append(
		$(
			`<div class="result-overlay">
				<div class="result-overlay__inner">${ text }</div>
			</div>`
		).hide().fadeIn( 150 )
	)
}



// $( window ).on( 'ajaxs_always', function ()
// {
// 	$loader.remove()
// } )
$( window ).on( 'ajaxs_always', () => $loader.fadeOut( 150, () => $loader.remove() ) ) //
